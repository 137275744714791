import Footer from "../components/Footer";
import "../user/profile.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
//import MoveUpIcon from "@mui/icons-material/MoveUp";
import AddCardOutlinedIcon from "@mui/icons-material/AddCardOutlined";
import PriceChangeOutlinedIcon from "@mui/icons-material/PriceChangeOutlined";
//import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import LockPersonOutlinedIcon from "@mui/icons-material/LockPersonOutlined";
import LockResetIcon from "@mui/icons-material/LockReset";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import LockOpenIcon from "@mui/icons-material/LockOpen";
//import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import AnimatedPage from "../components/AnimatedPage";

function Profile() {
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")));
    const [setting, setSetting] = useState(JSON.parse(localStorage.getItem("setting")));
    const navigate = useNavigate();
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                if (profile != res.data.data) {
                    setProfile(res.data.data);
                    localStorage.setItem("profile", JSON.stringify(res.data.data));
                }
            })
            .catch((err) => localStorage.removeItem("user"));
        axios.get(`${process.env.REACT_APP_API_URL}/setting/get`, {}).then((res) => {
            if (setting != res.data.data[0]) {
                setSetting(res.data.data[0]);
                localStorage.setItem("setting", JSON.stringify(res.data.data[0]));
            }
        });
    }, []);
    return (
        <>
            <div className="main" style={{ padding: "0", background: "#f1f1f1" }}>
                {profile && (
                    <AnimatedPage>
                        <div className="info-block">
                            <div className="info-block-top">
                                <div>Xin chào, {profile?.username ? profile?.username : "Vui lòng đăng nhập/đăng ký"}</div>
                                <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        localStorage.removeItem("user");
                                        localStorage.removeItem("currentUser");
                                        localStorage.removeItem("profile");
                                        localStorage.removeItem("data");
                                        localStorage.removeItem("data1");
                                        var now = new Date();
                                        var time = now.getTime();
                                        var expireTime = time;
                                        now.setTime(expireTime);
                                        document.cookie = "jwt=;Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
                                        navigate("/login");
                                    }}>
                                    {profile?.username ? "Đăng xuất" : ""}
                                </div>
                            </div>
                            {profile && (
                                <div className="info-block-center">
                                    <div className="currency">VND(k)</div>
                                    <div className="balance">
                                        {Math.floor(profile.money).toLocaleString("vi-VN")}
                                        <div className="btn-update d20_small_autorenew"></div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="func-btn-block">
                            <button onClick={() => navigate("/recharge")} className="nrc-button action-btn" type="button">
                                <div className="icon">
                                    <i className="kicon-deposit"></i>
                                </div>
                                <span>Nạp tiền</span>
                            </button>
                            <button onClick={() => navigate("withdraw")} className="nrc-button action-btn" type="button">
                                <div className="icon">
                                    <i className="kicon-withdrawal"></i>
                                </div>
                                <span>Rút tiền</span>
                            </button>
                            <button onClick={() => navigate("/historycasino")} className="nrc-button action-btn" type="button">
                                <div className="icon">
                                    <i className="kicon-fin-history"></i>
                                </div>
                                <span>Lịch Sử Chơi Sảnh</span>
                            </button>
                            <button onClick={() => navigate("/historyplay")} className="nrc-button action-btn" type="button">
                                <div className="icon">
                                    <i className="kicon-game-history"></i>
                                </div>
                                <span>Lịch sử trò chơi</span>
                            </button>
                        </div>
                        <div className="account">
                            <div className="account__menu">
                                {/*<Link to="/detail" className="account__menu-item">
                                    <span>
                                        <PermIdentityIcon sx={{ fontSize: "25px" }} />
                                        Chi tiết tài khoản
                                    </span>
                                    <KeyboardArrowRightOutlinedIcon />
                                </Link>*/}
                                <Link to="/transfergame" className="account__menu-item">
                                    <span>
                                        <i className="d20_account_wallet"></i>
                                        Chuyển tiền vào sảnh
                                    </span>
                                    <KeyboardArrowRightOutlinedIcon />
                                </Link>
                                <Link to="/history" className="account__menu-item">
                                    <span>
                                        <i className="d20_account_mission"></i>
                                        Lịch sử chuyển tiền sảnh
                                    </span>
                                    <KeyboardArrowRightOutlinedIcon />
                                </Link>
                                <Link to="/withdraw_record" className="account__menu-item">
                                    <span>
                                        <i className="d20_account_withdrawal"></i>
                                        Lịch sử rút
                                    </span>
                                    <KeyboardArrowRightOutlinedIcon />
                                </Link>
                                <Link to="/recharge_record" className="account__menu-item">
                                    <span>
                                        <i className="d20_account_deposit"></i>
                                        Lịch sử nạp
                                    </span>
                                    <KeyboardArrowRightOutlinedIcon />
                                </Link>
                                <Link to="/addbank" className="account__menu-item">
                                    <span>
                                        <i className="d20_account_bankcard"></i>
                                        Liên kết ngân hàng
                                    </span>
                                    <KeyboardArrowRightOutlinedIcon />
                                </Link>
                                <Link to="/invite" className="account__menu-item">
                                    <span>
                                        <i className="d20_account_recommand"></i>
                                        Mời bạn bè
                                    </span>
                                    <KeyboardArrowRightOutlinedIcon />
                                </Link>
                                <Link to="/password" className="account__menu-item">
                                    <span>
                                        <i className="kicon-account"></i>
                                        Đổi mật khẩu đăng nhập
                                    </span>
                                    <KeyboardArrowRightOutlinedIcon />
                                </Link>
                                <Link to="/pass2" className="account__menu-item">
                                    <span>
                                        <i className="d20_account_deposit2"></i>
                                        Đổi mật khẩu rút tiền
                                    </span>
                                    <KeyboardArrowRightOutlinedIcon />
                                </Link>
                                <Link to="/notification" className="account__menu-item">
                                    <span>
                                        <i className="d20_account_gift"></i>
                                        Khuyến mãi
                                    </span>
                                    <KeyboardArrowRightOutlinedIcon />
                                </Link>
                                <Link to="" className="account__menu-item">
                                    <span>
                                        <i className="d20_system_cellphone"></i>
                                        Tải App
                                    </span>
                                    <KeyboardArrowRightOutlinedIcon />
                                </Link>
                                <Link to="/service" className="account__menu-item">
                                    <span>
                                        <i className="d20_system_info"></i>
                                        Chăm sóc khách hàng
                                    </span>
                                    <KeyboardArrowRightOutlinedIcon />
                                </Link>
                            </div>
                        </div>
                    </AnimatedPage>
                )}
            </div>
            <Footer profile={profile} />
        </>
    );
}
export default Profile;
